import bg from '@/assets/purchaseOrder/bg.png'
import Process from './components/Process.vue'
import moment from "moment";
import {
    // 销售出库订单列表查询
    getOrderOutList,
    // 获取出库表格信息
    getTableList,
    // 订单出库
    saleOrderOut,
    difference,
    getTableListA
} from './api'
import { getDeliveryAmountFind, getTOF } from "@/views/stockCenter/adjustmentApply/allot/api";
moment.locale("zh-cn");
export default {
    name: "channelWarehouse",
    components: {
        Process
    },
    data() {
        return {
            breadcrumbData: [{
                    path: "/index",
                    name: "index",
                    title: "首页"
                },
                {
                    path: "/channelOrder/orderList",
                    name: "orderList",
                    title: "订单列表"
                },
                {
                    path: "/order/detail",
                    name: "detail",
                    title: "订单详情"
                }
            ],
            bg: bg,
            //表格搜索
            tableSearch: '',
            columns: [{
                    title: '仓库',
                    dataIndex: 'warehouseName',
                    width: 140,
                    ellipsis: true
                },
                {
                    title: '产品型号',
                    dataIndex: 'model',
                    width: 160,
                    ellipsis: true
                },
              {
                title: '物料号',
                dataIndex: 'materialCode',
                width: 140,
                ellipsis: true
              },

                {
                    title: '质量等级',
                    dataIndex: 'quvlitylv',
                    width: 90,
                },
                {
                    title: '补差类型',
                    dataIndex: 'invStatusTypeName',
                    width: 90
                },
                {
                    title: '库存价格（元）',
                    dataIndex: 'price',
                    width: 100,
                },
                {
                    title: '可出库数量',
                    dataIndex: 'bigQty',
                    width: 100,
                },
                {
                    title: '本次出库数量',
                    dataIndex: 'numberThis',
                    scopedSlots: {
                        customRender: "count"
                    }
                },
            ],
            // 表格数据
            tableData: [{
                    warehouse: `Edward King `,
                    level: 32,
                    size: `London, Park Lane no. `,
                    price: '20',
                    numberok: '30',
                    numberThis: '20'
                },
                {
                    warehouse: `Edward King `,
                    level: 32,
                    size: `London, Park Lane no. `,
                    price: '10',
                    numberok: '30',
                    numberThis: '10'
                }
            ],
            // 商品list
            goodsList: [{
                name: 'BCD-630WTDGVBPI/A玛瑙钛',
                color: '清新白',
                voloum: '0.3',
                pinpai: 'hisense',
                price: '2000',
                zhiliang: 'yes',
                bucha: 'no',
                number: '5',
                daishenhe: '10',
                total: '20000',
                // 表格数据
                tableData: [{
                        warehouse: `Edward King `,
                        level: 32,
                        size: `London, Park Lane no. `,
                        price: '20',
                        numberok: '30',
                        numberThis: '20',
                        data: 1
                    },
                    {
                        warehouse: `Edward King `,
                        level: 32,
                        size: `London, Park Lane no. `,
                        price: '10',
                        numberok: '30',
                        numberThis: '10',
                        data: 1
                    }
                ],
            }, {
                name: 'BCD-630WTDGVBPI/A玛瑙钛',
                color: '清新白',
                voloum: '0.3',
                pinpai: 'hisense',
                price: '2000',
                zhiliang: 'yes',
                bucha: 'no',
                number: '5',
                daishenhe: '10',
                total: '20000',
                // 表格数据
                tableData: [{
                        warehouse: `Edward King `,
                        level: 32,
                        size: `London, Park Lane no. `,
                        price: '20',
                        numberok: '30',
                        numberThis: '20',
                        data: 2
                    },
                    {
                        warehouse: `Edward King `,
                        level: 32,
                        size: `London, Park Lane no. `,
                        price: '10',
                        numberok: '30',
                        numberThis: '10',
                        data: 2
                    }
                ],
            }],
            // 仅查看已选
            onlyShowChecked: false,
            sendObject: [],
            // 基本信息数据
            orderDetail: {
                orderHeader: {
                    documentNum: '订单编号',
                    documentType: '订单类型',
                    statusName: '订单状态',
                    // canCancel: '123',
                    orgName: '供应商',
                    customerName: '客户',
                    billToName: '结算单位',
                    documentDate: '下单时间：',
                    purchaseNum: '关联采购单号：',
                    addressDesc: '收货地址明细',
                    deliveryTypeTitleValue: '配送方式：',
                    contact: '联系人：',
                    contactInfo: '联系电话：',
                    edt: '期望送货日期：',
                    isAllowAdvancDeliver: '允许提前交货：',
                    payType: '支付方式：',
                    dataSource: '来源',
                    customerCode: '下单账号',
                    message: '订单来源：'
                }
            },
            // 接口数据备份
            NeworderDetail: {
                NeworderHeader: {}
            },
            selectedRows: [],
            chukuTotal: 0,
            chukuVolume: 0,
            daichuku: 0,
            // 全选list
            selectedRowKeys: [],
            // 搜索用
            orgCode: '',
            searchLength: 0,
            model: '',
            pageLoadFlag: true,
            isLoading: false,
            // buchais: false,
            visibleshop:false,
          cherkStatus:false,
          visibleAAA:false,
          showTips:false,
          msgStatus:''

        };
    },
    mounted() {
        // 销售出库列表查询
        this.getOrderList();
        // 获取出库表格信息
        // this.getTable()
    },
    methods: {
      changeStatus(){
        this.cherkStatus = !this.cherkStatus
        this.$forceUpdate()
      },
        // 判断商品是否包含免运费标识
        panduanfreeTip() {
            let mian = []
            this.orderDetail.orderHeader.salesOrderItem.forEach(item=>{
            if(item.productLabel && item.productLabel.indexOf('15691143850')!==-1) {
                mian.push(item)
            }
            })
            if(mian.length>0 &&  this.orderDetail.orderHeader.deliveryTypeCode !== '04') {
            this.visibleshop = true
            } else {
            this.orderSubmit()
            }
        },
        // 销售出库列表查询
        // userAccount: this.$store.state.user.userInfo.account.account,
        getOrderList() {
            let data = {
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
                salesOrderId: this.$route.query.id
            }

            getOrderOutList(data).then(res => {
                var realData = JSON.parse(res.data);
                // this.bucha(realData.data.orgId)
                if (realData.code == '1') {
                    this.$message.warning(realData.msg);
                    this.$router.go(-1);
                }
                var dataList = realData.data.salesOrderItem;
                this.orgCode = realData.data.orgCode;

                var newArray = [];
                for (var i = 0; i < dataList.length; i++) {
                    let datas = {};
                    this.daichuku = this.daichuku + Number(dataList[i].backnowledgedQty);
                    //  分销商采购单查询条件
                    if (realData.data.documentType == 'normal' && realData.data.purchaseNum != '') {
                        datas = {
                            cisCode: this.$store.state.user.userInfo.customer.cisCode,
                            terms: {
                                isFuzzy:false,
                                code:dataList[i].productCode,
                                zzprdmodel:dataList[i].zzprdmodel,
                                orgCode: realData.data.orgId,
                                warehouseId: dataList[i].warehouseId,
                                invStatusId: dataList[i].invStatusId,
                                model: dataList[i].model,
                                invType: 110,
                                materialCode:dataList[i].materialCode

                            },
                            page: {
                                pageNo: 1,
                                pageSize: 100
                            }
                        }
                    } else {
                        datas = {
                            cisCode: this.$store.state.user.userInfo.customer.cisCode,
                            terms: {
                                isFuzzy:false,
                                zzprdmodel:dataList[i].zzprdmodel,
                                code:dataList[i].productCode,
                                orgCode: realData.data.orgId,
                                model: dataList[i].model,
                                invType: 110,
                                materialCode:dataList[i].materialCode
                            },
                            page: {
                                pageNo: 1,
                                pageSize: 100
                            }
                        }
                    }
                    this.pageLoadFlag = false;
                    // this.getTable(data, i)
                    // 查询商品库存
                  let productCode = []
                  productCode.push(dataList[i].productCode)
                  let dataA = {
                    documentNum:realData.data.documentNum,
                    productCodes:productCode
                  }
                    newArray.push(getTableList(datas))
                };
                Promise.all(newArray).then(value => {
                      for (let i = 0; i < value.length; i++) {
                        // var newArrays = [];
                        // 如果是gic预留  则可出库数量 == 预留数量
                        // 非 gic预留仓库
                        // 本次出库数量需要 小于 可出库数量 - 已出库数量
                        var kechuku = 0;
                        if (value[i].data) {
                          var realValue = JSON.parse(value[i].data);
                          realData.data.salesOrderItem[i].bencichuku = 0;
                          realData.data.salesOrderItem[i].checkAll = false;
                          realData.data.salesOrderItem[i].selectedRowKeys = [];
                          for (let y = 0; y < realValue.data.length; y++) {
                            realValue.data[y].index = i;
                            realValue.data[y].price = realData.data.salesOrderItem[i].bprice;
                            realValue.data[y].numberThis = 0;
                            realValue.data[y].key = y;
                            realValue.data[y].selectedTableRow = false;
                            // 可用库存
                            let jobQty = realValue.data[y].bigQty
                            if (realValue.data[y].isShare == '1') {
                              realValue.data[y].bigQty = realValue.data[y].bigQtyLock;
                            } else {
                              realValue.data[y].bigQty = Number(realValue.data[y].bigQty) - Number(dataList[i].shippedBqty);
                            }
                            // 如果来源是job 赋值可用库存
                            if(realData.data && realData.data.dataSource == 'job') {
                              realValue.data[y].bigQty = Number(jobQty);
                            }
                            kechuku = Number(kechuku) + realValue.data[y].bigQty;
                          }
                          realData.data.salesOrderItem[i].searchLength = realValue.data.length;
                          realData.data.salesOrderItem[i].tableList1 = realValue.data;
                          realData.data.salesOrderItem[i].kechuku = Number(kechuku);

                          // 判断详情中的仓库和所获得的仓库id是否一致 一致的话就指定当前仓库 如果不一致的话就展示全部让用户自己勾选
                          realData.data.salesOrderItem[i].tableList = []
                          realData.data.salesOrderItem[i].tableList1.forEach((item,index)=>{
                            dataList.forEach((saleItem,saleIndex) =>{
                              // 判断循环的saleIndex 和 realData.data.salesOrderItem的index 是否一致 一致才会进行下一步判断
                              if(saleIndex === i) {
                                if( item.gicWarehouse === saleItem.warehouseId ) {
                                  // 判断产品productCode 是否一致  一致才push数组中
                                  if(item.productCode === saleItem.productCode) {
                                    realData.data.salesOrderItem[i].tableList.push(item)
                                  }
                                  let kechuku = 0
                                  let bencichuku = 0
                                  // 本次出库需要赋值为详情中商品的可出库库存
                                  realData.data.salesOrderItem[i].bencichuku = saleItem.backnowledgedQty
                                  for (let y = 0; y < realValue.data.length; y++) {
                                    // 数字选择器赋值为详情中商品的可出库库
                                    realValue.data[y].numberThis = saleItem.backnowledgedQty
                                  }
                                  realData.data.salesOrderItem[i].searchLength =  realData.data.salesOrderItem[i].tableList.length;
                                  // 可用库存重新赋值
                                  realData.data.salesOrderItem[i].tableList .forEach(pitem=>{
                                    kechuku = Number(kechuku) + Number(pitem.bigQty);
                                    bencichuku = bencichuku+= pitem.numberThis
                                  })
                                  realData.data.salesOrderItem[i].kechuku = Number(kechuku);
                                  realData.data.salesOrderItem[i].bencichuku = 0
                                } else {
                                  // 如果详情仓库id和仓库列表不符合 保持原来的赋值方式
                                  realData.data.salesOrderItem[i].tableList = realValue.data;
                                  realData.data.salesOrderItem[i].searchLength =  realData.data.salesOrderItem[i].tableList.length;
                                  let chuku = 0
                                  // 仓库id如果对应不上可用库存重新赋值
                                  realData.data.salesOrderItem[i].tableList .forEach(pitem=>{
                                    chuku = chuku + pitem.bigQty;
                                  })
                                  realData.data.salesOrderItem[i].kechuku = Number(chuku);

                                }
                              }

                            })
                          })
                        }
                      }
                      this.orderDetail.orderHeader = realData.data;
                      this.NeworderDetail.NeworderHeader = JSON.parse(JSON.stringify(realData.data));
                      this.$forceUpdate();


                })
            }).finally(() => {
                this.pageLoadFlag = false;
            })
        },
        // bucha
        bucha(orgCode) {
            let data = {
                orgCode,
                cisCode: this.$store.state.user.userInfo.customer.cisCode,
            }
            difference(data).then(res => {
                var realData = JSON.parse(res.data);
                this.buchais = realData.data;
            })
        },
        // 搜索仓库
        tableSearchClick(item, index) {
            var newarray = [];
            for (let item of this.orderDetail.orderHeader.salesOrderItem[index].tableList) {
                var indexok = item.warehouseName.indexOf(this.model)
                if (indexok != -1) {
                    newarray.push(item);
                }
            }
            let tota = 0
            for (var i = 0; i < newarray.length; i++) {
                tota += Number(newarray[i].bigQty)
            }
            this.orderDetail.orderHeader.salesOrderItem[index].kechuku = Number(tota);
            this.orderDetail.orderHeader.salesOrderItem[index].searchLength = newarray.length;
            this.orderDetail.orderHeader.salesOrderItem[index].tableList = newarray;
        },
        // 搜索框清空
        handleinput(index, e) {
            let tota = 0
            this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList;
            this.orderDetail.orderHeader.salesOrderItem[index].searchLength = this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList.length;
            for (var i = 0; i < this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList.length; i++) {
                tota += Number(this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList[i].bigQty)
            }
            this.orderDetail.orderHeader.salesOrderItem[index].kechuku = Number(tota);
        },
        // 清空选择
        clearChose(index) {
            this.selectedRowKeys = [];
            this.orderDetail.orderHeader.salesOrderItem[index].checkAll = false;
            this.orderDetail.orderHeader.salesOrderItem[index].selectedRowKeys = [];
            this.sendObject[index] = [];
            var goodsNum = 0,
                goodsVol = 0;
            for (var i = 0; i < this.sendObject.length; i++) {
                for (var y = 0; y < this.sendObject[i].length; y++) {
                    goodsNum = goodsNum + Number(this.sendObject[i][y].numberThis);
                    goodsVol = goodsVol + Number(this.sendObject[i][y].volume);
                }
            }
            this.chukuTotal = goodsNum;
            this.chukuVolume = goodsVol;
        },

        onSelect(record, selected, selectedRows) {
            record.selectedTableRow = selected;
            var totalIndex = record.index;
            this.sendObject[totalIndex] = selectedRows;
            // 循环遍历表格 如果表格勾选为true 循环叠加每个numberThis
            let num = 0
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].tableList.forEach(item=>{
                if(item.selectedTableRow ) {
                    num += Number(item.numberThis)
                }
            })
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = num

            var newarray = [];
            for (let i = 0; i < selectedRows.length; i++) {
                newarray.push(selectedRows[i].key);
            }
            this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;
            // 计算选中商品 体积 数量
            var goodsnum = 0,
                goodsvol = 0;
            for (var y = 0; y < this.sendObject.length; y++) {
                if (this.sendObject[y]) {
                    for (var z = 0; z < this.sendObject[y].length; z++) {
                        goodsnum = goodsnum + Number(this.sendObject[y][z].numberThis);
                        // 修改体积计算错误
                        goodsvol = (Number(goodsvol) + (Number(this.sendObject[y][z].volume)* Number(this.sendObject[y][z].numberThis))).toFixed(3)
                      if(this.sendObject[y][z]&&this.sendObject[y][z].gicWarehouseType == 20) {
                      }
                    }
                }
            }
            this.chukuTotal = goodsnum;
            this.chukuVolume = goodsvol;
        },
        // 表格全选
        onSelectAll(selected, selectedRows, changeRows) {
            var newarray = [];
            var chosenumber = 0;
            var totalIndex = changeRows[0].index;
            this.sendObject[totalIndex] = selectedRows;
            if (selected) {
                for (let i = 0; i < changeRows.length; i++) {
                    newarray.push(changeRows[i].key)
                    changeRows[i].selectedTableRow = selected;
                }
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;
                 // 循环遍历表格 如果表格勾选为true 循环叠加每个numberThis
                let num = 0
                changeRows.forEach(item=>{
                    if(item.selectedTableRow ) {
                        num += Number(item.numberThis)
                    }
                })
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = num
                var goodsnum = 0,
                goodsvol = 0;
                for (var y = 0; y < this.sendObject.length; y++) {
                    if (this.sendObject[y]) {
                        for (var z = 0; z < this.sendObject[y].length; z++) {
                            goodsnum = goodsnum + Number(this.sendObject[y][z].numberThis);
                            goodsvol = goodsvol + Number(this.sendObject[y][z].volume)
                          if(this.sendObject[y][z]&&this.sendObject[y][z].gicWarehouseType == 20) {
                          }
                        }
                    }
                }
                this.chukuTotal = goodsnum;
                this.chukuVolume = goodsvol;
            } else {
                for (let i = 0; i < changeRows.length; i++) {
                    chosenumber += Number(changeRows[i].numberThis)
                    changeRows[i].numberThis = 0;
                    changeRows[i].selectedTableRow = selected;

                }
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].selectedRowKeys = newarray;
                
                let num = 0
                changeRows.forEach(item=>{
                    if(item.selectedTableRow ) {
                        num += Number(item.numberThis)
                    }
                   
                })
                this.orderDetail.orderHeader.salesOrderItem[totalIndex].bencichuku = num
                this.chukuTotal = this.chukuTotal - chosenumber;
                this.openCloseBtn = false
                this.cherkStatus = false
            }

        },
        // 仅查看已选
        onlyShowCheckedChange(item, index, e) {
            if (e.target.checked) {
                this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.sendObject[index];
            } else {
                this.orderDetail.orderHeader.salesOrderItem[index].tableList = this.NeworderDetail.NeworderHeader.salesOrderItem[index].tableList;
            }
            this.orderDetail.orderHeader.salesOrderItem[index].checkAll = e.target.checked;
        },
        //出库数量改变
        inputNumberChange(row) {
            var realNumber = this.orderDetail.orderHeader.salesOrderItem[row.index];
            var realData = this.orderDetail.orderHeader.salesOrderItem;
            var chukuNumber = 0;
            for (let i = 0; i < realNumber.tableList.length; i++) {
                if(realNumber.tableList[i].selectedTableRow) {
                    chukuNumber += Number(realNumber.tableList[i].numberThis);
                }
            }
            this.orderDetail.orderHeader.salesOrderItem[row.index].bencichuku = chukuNumber;
            var chukuzong = 0
            var chukutiji = 0
            for (let y = 0; y < realData.length; y++) {
                chukuzong += Number(realData[y].bencichuku);
                chukutiji += Number(Number(realData[y].bencichuku) * realData[y].volume);
            }
            this.chukuTotal = chukuzong;
            this.chukuVolume = this.$util.getFloat(chukutiji, 3);
        },
        gotoCK(){
          this.$router.push({
            path:"/stockCenter/myStock"
          });
        },
        //提交
       async orderSubmit() {
        // 如果dataSource == job  不进行出库验证 
          if(this.orderDetail.orderHeader && this.orderDetail.orderHeader.dataSource == 'job') {
            let realData = this.sendObject;

            if (realData.length == 0) {
                this.$message.warning('请先选择仓库');
                return;
            } else {
              let TJmoney = 0
                let  submitArray = [];
                let  realValue = this.orderDetail.orderHeader.salesOrderItem
                let isFinished = true;
                for (let i = 0; i < realData.length; i++) {
                    let submitData = {},
                        outThis = 0;
                    if (realData[i]) {
                        for (let z = 0; z < realData[i].length; z++) {

                            if (realData[i][z].numberThis == 0) {
                                this.$message.warning('第' + Number(i + 1) + '个商品仓库数据不能为0')
                                return;
                            }
                            if (realValue[i].bencichuku < realValue[i].backnowledgedQty - realValue[i].shippedBqty) {
                                isFinished = false;
                            }
                            outThis = outThis + realData[i][z].numberThis;
                            if(realData[i][z].gicWarehouseType == '20') {
                              TJmoney += (realData[i][z].numberThis * realData[i][z].price)
                            }
                           submitData = {
                                materialGroupCode: realData[i][z].materialGroupCode,
                                invStatusId: realData[i][z].invStatusId,
                                backnowledgedQty: realValue[i].backnowledgedQty,
                                iceBoxNum: '', //realValue[i].iceBoxNum,
                                invStatusType: realData[i][z].invStatusType,
                                productName: realData[i][z].productName,
                                itemId: realValue[i].itemId,
                                colour: realData[i][z].colour,
                                productCode: realValue[i].productCode,
                                warehouseId: realData[i][z].gicWarehouse,
                                model: realData[i][z].model,
                                shippedBqty: realData[i][z].numberThis,
                                bprice: realValue[i].bprice,
                                invStatus: realData[i][z].invStatusId,
                                invBatchId: ''
                            }
                            submitArray.push(submitData);
                        }
                    }
                }
                if (submitArray.length == 0) {
                    this.$message.warning('请先选择仓库');
                    return;
                }
                this.isLoading = true;
                let datas = {
                    salesOrderId: this.$route.query.id,
                    salesOrderItem: submitArray,
                    isFinished: isFinished,
                    dataSource: 'xtw_pc'
                }
                // 订单出库
                let data = {
                    cisCode: this.$store.state.user.userInfo.customer.cisCode,
                    userAccount: this.$store.state.user.userInfo.account.account,
                    data: datas
                }
                saleOrderOut(data).then(res => {
                    this.isLoading = false;
                    let realData = JSON.parse(res.data);
                    if (realData.code == '1') {
                        this.$message.warning(realData.msg);
                    } else if (realData.code == '0') {
                        this.$message.success('出库成功')
                        localStorage.setItem('XTW', new Date());
                        localStorage.setItem("saleOrderListTime", new Date().getTime());
                        window.close()
                        this.$router.push('/channelOrder/orderList');
                    }
                }).finally(() => {
                    this.isLoading = false;
                })
            }
          } else {
            if (this.daichuku < this.chukuTotal) {
                this.$message.warning('出库数量不得大于待出库数量');
                return;
            }
            let realData = this.sendObject;
            if (realData.length == 0) {
                this.$message.warning('请先选择仓库');
                return;
            } else {

                let  submitArray = [];
                let  realValue = this.orderDetail.orderHeader.salesOrderItem
                let isFinished = true;
                let TJmoney = 0
                for (let i = 0; i < realData.length; i++) {
                    let submitData = {},
                        outThis = 0;
                    if (realData[i]) {
                        for (let z = 0; z < realData[i].length; z++) {
                         if (realData[i][z].numberThis == 0) {
                                this.$message.warning('第' + Number(i + 1) + '个商品仓库数据不能为0')
                                return;
                            }
                            if (realValue[i].bencichuku > realValue[i].backnowledgedQty - realValue[i].shippedBqty) {
                                this.$message.warning('出库数量不能大于已审核的剩余出库数量')
                                return;
                            }
                            if (realValue[i].bencichuku < realValue[i].backnowledgedQty - realValue[i].shippedBqty) {
                                isFinished = false;
                            }
                            outThis = outThis + realData[i][z].numberThis;
                            if(realData[i][z].gicWarehouseType == '20') {
                              TJmoney += (realData[i][z].numberThis * realData[i][z].price)
                            }

                          submitData = {
                                materialGroupCode: realData[i][z].materialGroupCode,
                                invStatusId: realData[i][z].invStatusId,
                                backnowledgedQty: realValue[i].backnowledgedQty,
                                iceBoxNum: '', //realValue[i].iceBoxNum,
                                invStatusType: realData[i][z].invStatusType,
                                productName: realData[i][z].productName,
                                itemId: realValue[i].itemId,
                                colour: realData[i][z].colour,
                                productCode: realValue[i].productCode,
                                warehouseId:realData[i][z].gicWarehouse,
                                model: realData[i][z].model,
                                shippedBqty: realData[i][z].numberThis,
                                bprice: realValue[i].bprice,
                                invStatus: realData[i][z].invStatusId,
                                invBatchId: ''
                            }
                            submitArray.push(submitData);
                        }
                        if (outThis > realValue[i].backnowledgedQty) {
                            this.$message.warning('第' + Number(i + 1) + '个商品出库数量大于商品该出库数量')
                            return;
                        }
                    }
                }
                if (submitArray.length == 0) {
                    this.$message.warning('请先选择仓库');
                    return;
                }
                this.isLoading = true;
                let datas = {
                    salesOrderId: this.$route.query.id,
                    salesOrderItem: submitArray,
                    isFinished: isFinished,
                    dataSource: 'xtw_pc'
                }
                // 订单出库
                let data = {
                    cisCode: this.$store.state.user.userInfo.customer.cisCode,
                    userAccount: this.$store.state.user.userInfo.account.account,
                    data: datas
                }
                
                saleOrderOut(data).then(res => {
                    this.isLoading = false;
                    let realData = JSON.parse(res.data);
                    if (realData.code == '1') {
                        this.$message.warning(realData.msg);
                    } else if (realData.code == '0') {

                        this.$message.success('出库成功')
                        localStorage.setItem('XTW', new Date());
                        localStorage.setItem("saleOrderListTime", new Date().getTime());
                        window.close()
                        this.$router.push('/channelOrder/orderList');
                    }
                }).finally(() => {
                    this.isLoading = false;
                })
            }
          }
        },
        // 取消
        orderCancle() {
            localStorage.setItem('XTW', new Date());
            window.close()
            this.$router.push('/channelOrder/orderList');
        },
        // 
        formatDate(date) {
            return moment(date).format('YYYY-MM-DD HH:mm:ss');
        },
    },
};